<template>
  <div id="addUser">
    <div class="text-right mb-2">
      <b-button @click="status = true" variant="success">Add User</b-button>
    </div>
    <!-- Add Modal -->
    <b-modal
      v-model="status"
      id="modal-center"
      centered
      title="Add User"
      ok-title="Add"
      @ok="add"
    >
      <!-- Loading -->
      <div v-if="loading" class="text-center">
        <b-spinner style="width: 3rem; height: 3rem" />
      </div>
      <!-- User Content -->
      <div v-else>
        <!-- name -->
        <b-form-group label="Name" label-for="name">
          <b-form-input v-model="form.name" placeholder="Enter Name" />
        </b-form-group>
        <!-- username -->
        <b-form-group label="Username" label-for="username">
          <b-form-input v-model="form.username" placeholder="Enter username" />
        </b-form-group>
        <!-- e-mail -->
        <b-form-group label="E-mail" label-for="email">
          <b-form-input v-model="form.email" placeholder="Enter email" />
        </b-form-group>
        <!-- password -->
        <b-form-group label="password" label-for="email">
          <b-form-input v-model="form.password" placeholder="Enter password" />
        </b-form-group>
        <!-- country -->
        <b-input-group class="input-group-merge mb-1">
          <v-select
            class="country-list"
            v-model="form.country"
            label="title"
            :options="countries"
            placeholder="Select country"
          />
        </b-input-group>
        <!-- User group -->
        <b-form-checkbox
          @input="(v) => (form.group = v ? 'RESELLER' : 'USER')"
          :checked="form.group == 'RESELLER'"
          class="custom-control-primary"
          name="check-button"
          switch
          inline
        >
          Reseller Group
        </b-form-checkbox>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import countries from "@/libs/countries.js";
import User from "@/services/user.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data: () => ({
    loading: false,
    status: false,
    form: {
      name: null,
      username: null,
      email: null,
      password: null,
      country: null,
      group: null,
    },
    countries,
  }),
  methods: {
    async add() {
      this.loading = true;
      try {
        await User.add(this.form);
        this.$emit("added");
      } catch (e) {
        console.log(e);
        const msg =
          e.response.status == 422
            ? Object.values(e.response.data.errors)[0][0]
            : "Something went wrong, please try again";
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Faild to add",
            icon: "XIcon",
            text: msg,
            variant: "danger",
          },
        });
      }
      this.loading = false;
    },
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    vSelect,
    // eslint-disable-next-line vue/no-unused-component
    ToastificationContent,
    BFormCheckbox,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.country-list {
  width: 100%;
}
</style>