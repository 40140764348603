<template>
  <section id="listUsers">
    <!-- Add User -->
    <AddUser @added="refresh" />
    <!-- Table View -->
    <VueDatatable ref="myTable" :setupTable="setupTable">
      <template v-slot:thead>
        <tr>
          <th class="id-col" style="width: 59px">
            <span>ID</span>
          </th>
          <th style="width: 65px">Username</th>
          <th>E-mail</th>
          <th>Country</th>
          <th class="none">Provider</th>
          <th>Group</th>
          <th>Created</th>
          <th class="none">Credit</th>
          <th>Actions</th>
        </tr>
      </template>
    </VueDatatable>
    <!-- Edit User -->
    <EditUser @updated="refresh" />
  </section>
</template>

<script>
import { BButton } from "bootstrap-vue";
import VueDatatable from "@/components/util/vue-datatable/Index.vue";
import { setupTable } from "@/datatable/users.datatable.js";
import EditUser from "./EditUser";
import AddUser from "./AddUser";

export default {
  data: () => ({
    dataTable: null,
    setupTable,
  }),
  async mounted() {
    this.dataTable = this.$refs.myTable;
  },
  methods: {
    refresh() {
      this.myTable.access().ajax.reload();
    },
  },
  computed: {
    myTable() {
      return this.dataTable || {};
    },
  },
  components: {
    BButton,
    VueDatatable,
    EditUser,
    AddUser,
  },
};
</script>

<style>
</style>