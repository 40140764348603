import $ from "jquery";
import router from "@/router/index";
import exportBtns from "./util/export-buttons"
import { serverUrl } from "@/global/variables";

window.goPage = (page, user_id) => {
    router.push({ name: page, params: { user_id } })
}

// Setup Table
export const setupTable = () => {
    return $("#vue-datatable").DataTable({
        processing: true,
        serverSide: true,
      //  responsive: false,
	//	scrollX: true,


        ajax: {
            url: serverUrl("/api/users"),
            beforeSend: function (request) {
                request.setRequestHeader(
                    "Authorization",
                    `Bearer ${localStorage.getItem("token")}`
                );
            },
        },
        buttons: [...exportBtns(), "columnsToggle"],
        dom: "Bfrtip",
		pageLength: 50,
        columns: [
            { data: "id" },
            { data: "username" },
            { data: "email" },
            { data: "country" },
            { data: "provider.provider" },
            { data: "group" },
          //  { data: "user_type" },
          { data: "created_at" },
            { data: "credit" },
            { name: "actions", data: "actions" },
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true,
            },
            {
                targets: 1,
                orderable: false,
            },
            {
                targets: 2,
                orderable: false,
            },
            {
                targets: 4,
                orderable: false,
                render: function (data, type, row) {
                    const classesBadge = [
                        "badge",
                        `badge-dark`,
                        "badge-pill",
                    ].join(" ");
                    return data && `<span class='${classesBadge}'>` + data + "</span>";
                },
            },
            {
                render: function (data, type, row) {
                    const classesBadge = [
                        "badge",
                        data === 'RESELLER' ? `badge-light-warning` : `badge-light-info`,
                        "badge-pill",
                    ].join(" ");
                    return data && `<span class='${classesBadge}'>` + data + "</span>";
                },
                targets: 5,
            },
            {
                render: function (data, type, row) {
                    const classesBadge = [
                        "badge",
                        data === 'ADMIN' ? `badge-light-success` : `badge-light-info`,
                        "badge-pill",
                    ].join(" ");
                    return data && `<span class='${classesBadge}'>` + data + "</span>";
                },
                targets: 6,
            },
            {
                render: function (data, type, row) {
                    const editBtn = `<button type="button" onClick="window.editUser(${row.id})" class="btn btn-icon rounded-circle btn-flat-success editBtn"><svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></button>`
                    const ordersBtn = `<button type="button" onClick="window.goPage('user-order', ${row.id})" class="btn btn-icon rounded-circle btn-flat-warning editBtn"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-list"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg></button>`
                    const servicePrices = `<button type="button" onClick="window.goPage('user-price', ${row.id})" class="btn btn-icon rounded-circle btn-flat-info editBtn"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></button>`
                    const invoiceBtn = `<button type="button" onClick="window.goPage('user-invoice', ${row.id})" class="btn btn-icon rounded-circle btn-flat-dark editBtn"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg></button>`
                    return editBtn + ordersBtn + servicePrices + invoiceBtn;
                },
                targets: 8,
            },
        ],
        order: [[0, "desc"]],
    });
}
