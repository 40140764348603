<template>
  <div id="editUser">
    <!-- Edit Modal -->
    <b-modal
      v-model="status"
      id="modal-center"
      centered
      title="Edit User"
      ok-title="Apply"
      @ok="apply"
    >
      <!-- Loading -->
      <div v-if="loading" class="text-center">
        <b-spinner style="width: 3rem; height: 3rem" />
      </div>
      <!-- User Content -->
      <div v-else>
        <!-- name -->
        <b-form-group label="Name" label-for="name">
          <b-form-input v-model="form.name" placeholder="Enter Name" />
        </b-form-group>
        <!-- username -->
        <b-form-group label="Username" label-for="username">
          <b-form-input v-model="form.username" placeholder="Enter username" />
        </b-form-group>
        <!-- e-mail -->
        <b-form-group label="E-mail" label-for="email">
          <b-form-input v-model="form.email" placeholder="Enter email" />
        </b-form-group>
        <!-- country -->
        <b-input-group class="input-group-merge mb-1">
          <v-select
            class="country-list"
            v-model="form.country"
            label="title"
            :options="countries"
            placeholder="Select country"
          />
        </b-input-group>
        <!-- password -->
        <b-button
          v-if="!allowUpdatePassword"
          class="mb-2"
          variant="relief-dark"
          @click="allowUpdatePassword = true"
          block
        >
          Update Password
        </b-button>
        <b-form-group
          v-if="allowUpdatePassword"
          label="Password"
          label-for="password"
        >
          <b-form-input
            v-model="form.password"
            placeholder="Enter new password"
          />
        </b-form-group>
        <!-- User group -->
        <b-form-checkbox
          @input="(v) => (form.group = v ? 'RESELLER' : 'USER')"
          :checked="form.group == 'RESELLER'"
          class="custom-control-primary"
          name="check-button"
          switch
          inline
        >
          Reseller Group
        </b-form-checkbox>
        <!-- deactivate -->
        <b-form-checkbox
          @input="(v) => (form.deactivate = v)"
          :checked="!!form.deactivate"
          class="custom-control-primary"
          name="check-button"
          switch
          inline
        >
          Deactivate
        </b-form-checkbox>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import countries from "@/libs/countries.js";
import User from "@/services/user.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data: () => ({
    allowUpdatePassword: false,
    loading: false,
    status: false,
    form: {
      id: null,
      name: null,
      username: null,
      email: null,
      country: null,
      password: null,
      group: null,
      deactivate: null,
    },
    countries,
  }),
  methods: {
    async apply() {
      this.loading = true;
      try {
        await User.update(this.form);
        this.$emit("updated");
      } catch (e) {
        const msg =
          e.response.status == 422
            ? Object.values(e.response.data.errors)[0][0]
            : "Something went wrong, please try again";
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Faild to update",
            icon: "XIcon",
            text: msg,
            variant: "danger",
          },
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    window.editUser = async (id) => {
      this.allowUpdatePassword = false;
      this.loading = true;
      this.status = true;
      const getUser = await User.specific(id);
      Object.keys(this.form).forEach((keyForm) => {
        this.form[keyForm] = getUser[keyForm];
      });
      this.loading = false;
    };
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    vSelect,
    // eslint-disable-next-line vue/no-unused-component
    ToastificationContent,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.country-list {
  width: 100%;
}
</style>